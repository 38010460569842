<template>

  <div class="pm-container mt-4">
    <router-link class="btn btn-primary" to="/mediathek">Zurück</router-link>
    <hr>

    <div v-if="!loading && video.videoBlobId" class="card">

      <vue-plyr controls crossorigin playsinline>
        <video class="card-img" playsinline controls>
          <source :src="videoLink" type="video/mp4"/>
        </video>
      </vue-plyr>


      <div class="card-body">
        <h5 class="card-title">📺 {{ video.title }}</h5>
        <p class="card-text">
          <VideoDetails :video="video"></VideoDetails>
        </p>
        <p class="card-text">{{ video.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import VideoDetails from "../../components/VideoDetails";

export default {
  name: "Video",
  components: {VideoDetails},
  data() {
    return {}
  },

  mounted() {
    this.$store.dispatch("videos/show", {token: this.$route.params.video});
  },

  computed: {
    video() {
      return this.$store.state.videos.currentVideo;
    },

    thumbnailLinkPreview() {
      return this.$filters.nonceLink(process.env.VUE_APP_API_URL + "/api/file/" + this.video.thumbnailBlobId);
    },

    videoLink() {
      return this.$filters.nonceLink(process.env.VUE_APP_API_URL + "/api/file/" + this.video.videoBlobId);
    },

    loading() {
      return this.$store.state.videos.loading;
    }
  }
}
</script>

<style scoped>

</style>